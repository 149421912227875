import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { createPaginationContainer, graphql } from 'react-relay';

import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeUserType from 'src/components/Badge/BadgeUserType';
import LoadMoreButton from 'src/components/LoadMoreButton';
import Loading from 'src/components/Loading';

import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import username from 'src/util/decorators/username';

import AdminUserControls from '../AdminUserControls';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminUserList component
 */
function AdminUserList(props) {
  const loadMore = () => {
    const { relay } = props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log(error); },
    );
  };

  if (!props) {
    return <Loading />;
  }

  const { relay, viewer } = props;
  const { users } = viewer;

  const intl = useIntl();

  const copyLoadMore = intl.formatMessage({ id: 'admin.admin_user_list.load_more_button.state_load_more.label', defaultMessage: 'Load more...' });
  const copyNoMore = intl.formatMessage({ id: 'admin.admin_user_list.load_more_button.state_no_more.label', defaultMessage: 'No more users to load' });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi Admin - Users</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Users' },
        ]}
      />

      <div className="clearfix">
        <h1 className="float-start">Users</h1>
        <div className="float-end mb-4">
          <AdminUserControls />
        </div>
      </div>

      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Type</th>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">
                  <abbr title="External identifier">Ext ID</abbr>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                // users && <pre>{JSON.stringify(users, null, 2)}</pre>
                users && users.edges && users.edges.map((edge) => {
                  const { node: user } = edge;
                  const {
                    id, externalIdentifier, type, email, active,
                  } = user;
                  const { start, finish } = active;

                  return (
                    <tr key={`admin-user-${id}`}>
                      <td>
                        <BadgeActive start={start} finish={finish} />
                        <br />
                        {DateTime.fromSeconds(start).toISO()}
                        {' to '}
                        {finish ? DateTime.fromSeconds(finish).toISO() : 'the future'}
                      </td>
                      <td>
                        <BadgeUserType type={type} />
                      </td>
                      <th scope="row" colSpan={3}>
                        <Link to={`/admin/users/${id}`}>
                          <div>{username(user)}</div>
                          <div>{email}</div>
                        </Link>
                        <div>{`(${externalIdentifier})`}</div>
                      </th>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <LoadMoreButton
            copyLoadMore={copyLoadMore}
            copyNoMore={copyNoMore}
            onClick={loadMore}
            hasNext={relay.hasMore()}
          />
        </div>
      </div>
    </>
  );
}

AdminUserList.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminUserList.defaultProps = {
  viewer: null,
};

export default createPaginationContainer(
  AdminUserList,
  {
    viewer: graphql`
      fragment AdminUserList_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        users(
          first: $count
          after: $cursor
        ) @connection(key: "AdminUserList_users") {
          edges {
            cursor
            node {
              id
              externalIdentifier
              type
              email
              givenName
              familyName
              active {
                start
                finish
              }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.users;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query AdminUserList_Pagination_Query(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...AdminUserList_viewer
      }
    }
  `,
  },
);
